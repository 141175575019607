<template>
<div>
  <crud-button 
    :onClickList="fetchUrunGrup"
    :onClickAddNew="addNew"
    />
  <b-card
    v-if="getUrunGrups"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="getUrunGrups"
      responsive
      :fields="tableColumns"
      class="mb-0"
      show-empty
      empty-text="Hiçbir eşleşen kayıt bulunamadı"
    >
      <!-- urungrup -->
      <template #cell(sAdi)="data">
        <div class="d-flex align-items-center">
            <div class="font-weight-bolder">
              {{ data.item.sAdi }}
            </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(sModul)="data">
        <div class="d-flex align-items-center">
            <feather-icon
              :icon="resolveModulTipiIcon(data.item.sModul)"
              size="18"
              class="mr-50"
              :class="`text-${resolveModulTipiVariant(data.item.sModul)}`"
            />
          <span>{{ resolveModulTipiName(data.item.sModul) }}</span>
        </div>
      </template>

      <template #cell(tur)="data">
          <b-badge
            pill
            :variant="`light-${resolveTurVariant(data.item.tur)}`"
            class="text-capitalize">
            {{ resolveTurName(data.item.tur) }}
          </b-badge>
        </template>
      
      <template #cell(actions)="data">
          <div class="text-right">
            <!-- Update -->
            <feather-icon
              :id="`user-row-${data.item.id}-edit-icon`"
              @click="$router.push({ name: 'manage-urungrup-save', params: { id: data.item.id }})"
              icon="EditIcon"
              class="cursor-pointer text-primary"
              size="16"
            />

            <!-- Remove -->
            <feather-icon
              :id="`user-row-${data.item.id}-remove-icon`"
              icon="Trash2Icon"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>
    </b-table>
  </b-card>
  </div>
</template>
<script>
import useUrunGrup from './useUrunGrup';

export default {
  methods: {
    addNew() {
      this.$router.push({ name: 'manage-urungrup-save', params: { id: -1 } });
    },
  },
  setup() {
    const {
      fetchUrunGrup,
      getUrunGrups,
      tableColumns,
      perPage,
      currentPage,
      toplamUrunGrup,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      UrunGrupTable,

      resolveModulTipiVariant,
      resolveModulTipiIcon,
      resolveTurVariant,
      resolveTurName,
      resolveModulTipiName,
    } = useUrunGrup()

    return {
      fetchUrunGrup,
      getUrunGrups,
      tableColumns,
      perPage,
      currentPage,
      toplamUrunGrup,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      UrunGrupTable,

      // UI
      resolveModulTipiVariant,
      resolveModulTipiIcon,
      resolveTurVariant,
      resolveTurName,
      resolveModulTipiName,
    }
  },
};

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
