import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useUrunGrup() {
  const UrunGrupTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Ad', key: 'sAdi' },
    { label: 'Modül', key: 'sModul' },
    { label: 'Tür', key: 'tur' },
    { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(10)
  const toplamUrunGrup = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getMusteriLocalFilters.sortBy
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getMusteriLocalFilters.sortDesc
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = UrunGrupTable.value ? UrunGrupTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamUrunGrup.value,
    }
  })

  const refetchData = () => {
    UrunGrupTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchUrunGrup = () => {
    store.dispatch('fetchUrunGrups', { })
  }

  const getUrunGrups = computed(() => {
    const urungrups = store.getters.getUrunGrups
    toplamUrunGrup.value = urungrups.length
    return urungrups
  })

  onMounted(fetchUrunGrup)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveModulTipiVariant = module => {
    if (module === 'tirkantar') return 'primary'
    if (module === 'beton') return 'warning'
    if (module === 'akaryakit') return 'success'
    if (module === 'aractakip') return 'success'
    if (module === 'erp') return 'success'
    if (module === 'eirsaliye') return 'success'
    return 'primary'
  }

  const resolveModulTipiIcon = module => {
    if (module === 'tirkantar') return 'TruckIcon'
    if (module === 'beton') return 'AlignJustifyIcon'
    if (module === 'akaryakit') return 'CommandIcon'
    if (module === 'aractakip') return 'NavigationIcon'
    if (module === 'erp') return 'CodesandboxIcon'
    if (module === 'eirsaliye') return 'FileTextIcon'
    return 'DiscIcon'
  }

  const resolveModulTipiName = module => {
    if (module === 'tirkantar') return 'Tır Kantar'
    if (module === 'beton') return 'Beton'
    if (module === 'akaryakit') return 'Akaryakıt'
    if (module === 'aractakip') return 'Araç Takip'
    if (module === 'erp') return 'ERP'
    if (module === 'eirsaliye') return 'E-İrsaliye'
    return 'DiscIcon'
  }

  const resolveTurVariant = tur => {
    if (tur === 'urun') return 'primary'
    if (tur === 'hizmet') return 'warning'
    return 'primary'
  }

  const resolveTurName = tur => {
    if (tur === 'urun') return 'Ürün'
    if (tur === 'hizmet') return 'Hizmet'
    return 'Bilinmiyor'
  }

  return {
    fetchUrunGrup,
    getUrunGrups,
    tableColumns,
    perPage,
    currentPage,
    toplamUrunGrup,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    UrunGrupTable,

    resolveModulTipiVariant,
    resolveModulTipiIcon,
    resolveTurVariant,
    resolveTurName,
    resolveModulTipiName,
  }
}
